import { Login } from "../../api/user";
import { getToken, setToken, removeToken } from "../../utils/cookie";

const getDefaultState = () => {
  return {
    token: getToken(),
    username: "",
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERNAME: (state, username) => {
    state.username = username;
  },
};

const actions = {
  login({ commit }, payload) {
    const { username, password } = payload;
    return new Promise((resolve, reject) => {
      Login({ username: username.trim(), password: password.trim() })
        .then((response) => {
          commit("SET_USERNAME", username.trim());
          commit("SET_TOKEN", response.token);
          setToken(response.token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  },

  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken();
      commit("RESET_STATE");
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
